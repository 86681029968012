<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button v-if="seachStatus" status="my-orange" @click="showSearch(false)">隐藏查询</vxe-button>
        <vxe-button
          v-if="!seachStatus"
          status="my-orange"
          icon="fa fa-search"
          @click="showSearch(true)"
        >查询</vxe-button>
        <vxe-button v-permission="'AddServeOrgs'" status="my-orange" icon="fa fa-plus" @click="goPage('add')">添加</vxe-button>
      </template>
    </vxe-toolbar>
    <div v-if="seachStatus" class="search">
      <vxe-form
        title-align="right"
        title-width="120"
        title-colon
        :data="searchData"
        @submit="searchEvent"
        @reset="searchReset"
      >
        <vxe-form-item title="企业名称" span="8">
          <vxe-input v-model="searchData.compName" placeholder="请输入企业名称" clearable />
        </vxe-form-item>
        <vxe-form-item title="审核状态" span="6">
          <vxe-select v-model="searchData.auditStatus" placeholder="请选择审核状态">
            <vxe-option
              v-for="(item, index) in auditStatusArray"
              :key="index"
              :label="item"
              :value="index"
            />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <div class="table">
      <vxe-grid border stripe resizable auto-resize :columns="tableColumn" :data="tableData" :loading="loading">
        <template v-slot:compName="{ row }">
          <p class="link-a" @click="goPage('detail', row)">{{ row.compName }}</p>
        </template>
        <template v-slot:operation="{ row }">
          <vxe-button v-permission="'AddServeOrgs'" type="text" status="primary" @click="goPage('edit', row)">修改</vxe-button>
          <vxe-button v-permission="'ServeOrgsDeleteButton'" type="text" status="primary" @click="remove(row.id)">删除</vxe-button>
        </template>
      </vxe-grid>
    </div>
    <!-- 分页 -->
    <div class="page">
      <vxe-pager
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :page-sizes="tablePage.pageSizes"
        :total="tablePage.totalResult"
        :layouts="layouts"
        @page-change="pageChange"
      />
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapActions
} from 'vuex'
export default {
  data() {
    return {
      loading: false,
      seachStatus: false,
      searchData: {},
      auditStatusArray: ['待审核', '正常', '拒绝'],
      // 分页的参数配置
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true
      },
      tableColumn: [
        {
          type: 'seq',
          title: '序号',
          width: 50,
          align: 'center'
        },
        {
          field: 'compName',
          title: '公司名称',
          showOverflow: true,
          align: 'center',
          minWidth: '200',
          slots: {
            default: 'compName'
          }
        },
        {
          field: 'serviceTimes',
          title: '服务次数',
          showOverflow: true,
          align: 'center',
          minWidth: '60'
        },
        {
          field: 'compAddres',
          title: '办公住址',
          showOverflow: true,
          align: 'center',
          minWidth: '120'
        },
        {
          field: 'contPerson',
          title: '联系人',
          showOverflow: true,
          align: 'center',
          minWidth: '80'
        },
        {
          field: 'contPhone',
          title: '联系方式',
          showOverflow: true,
          align: 'center',
          minWidth: '120'
        },
        {
          field: 'auditStatus',
          title: '审核状态',
          showOverflow: true,
          align: 'center',
          minWidth: '140',
          formatter: data => {
            if (data.row.auditStatus in this.auditStatusArray) {
              let text = this.auditStatusArray[data.row.auditStatus]
              if (data.row.auditStatus === 2 && data.row.reason) {
                text += `：${data.row.reason}`
              }
              return text
            } else {
              return ''
            }
          }
        },
        {
          field: 'account',
          title: '登录账号',
          showOverflow: true,
          align: 'center',
          minWidth: '120'
        },
        {
          title: '操作',
          minWidth: '120',
          showOverflow: true,
          slots: {
            default: 'operation'
          }
        }
      ],
      tableData: []
    }
  },
  computed: {
    ...mapState([
      'layouts'
    ])
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions([
      'getServeOrgsListServe',
      'delServeOrgsServe'
    ]),
    initView() {
      this.getLists()
    },
    // 是否显示查询功能
    showSearch(bool) {
      this.seachStatus = bool
    },
    goPage(type, itemData) {
      if (type == 'detail') {
        this.$router.push({
          name: 'ServeOrgsDetail',
          params: {
            type: type,
            id: itemData.id
          },
          query: {
            item: type == 'add' ? 'add' : JSON.stringify(itemData),
            cmOrEntId: type == 'add' ? 'add' : itemData.id

          }
        })
      } else {
        this.$router.push({
          name: 'AddServeOrgs',
          params: {
            type: type,
            id: type == 'add' ? 'add' : itemData.id
          },
          query: {
            item: type == 'add' ? 'add' : JSON.stringify(itemData)
          }
        })
      }
    },
    getLists() {
      this.loading = true
      this.getServeOrgsListServe({
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          hasTenant: true,
          compName: this.searchData.compName,
          auditStatus: this.searchData.auditStatus
        }
      }).then(res => {
        if (res.code == 200) {
          this.tableData = res.data.records
          this.tablePage.totalResult = +res.data.total
        }
        this.loading = false
      })
    },
    // 分页功能
    pageChange(item) {
      if (item.type == 'size') {
        this.tablePage.currentPage = 1
      } else {
        this.tablePage.currentPage = item.currentPage
      }
      this.getLists()
    },
    searchEvent() {
      this.seachStatus = false
      this.getLists()
    },
    searchReset() {
      this.searchData = {}
      this.getLists()
    },
    remove(id) {
      this.$XModal.confirm('您确定要删除该数据?').then(type => {
        if (type === 'confirm') {
          console.log('执行删除操作')
          this.delete(id)
        }
      })
    },
    delete(id) {
      this.delServeOrgsServe({
        id: id
      }).then(res => {
        if (res.code == 200) {
          this.$XModal.message({
            message: '删除成功',
            status: 'success'
          })
          this.getLists()
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
	.datetime {
		display: flex;
		align-items: center;

		span {
			margin: 0 3px;
		}
	}

}

.sign i {
	margin-right: 3px;
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 50%;
}</style>
